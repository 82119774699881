/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import globalStyles from "Styles/globalStyles";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";

const DemoPage = (props) => {
  const refreshFlags = useSelector(
    (state) => state.sideBarReducer.routeRefreshFlags
  );
  const flag = refreshFlags[props.link];
  const globalClasses = globalStyles();
  const homeIcon = [
    {
      label: props.title,
      id: 1,
    },
  ];
  const module = useMemo(() => {
    return (
      <>
        {props.breadcrumb === "Yes" && (
          <HeaderBreadCrumbs options={homeIcon}></HeaderBreadCrumbs>
        )}
        <div className={`${globalClasses.filterWrapper} page-content`}>
          <object
            style={{ width: "100%", height: "100%" }}
            data={`${process.env.REACT_APP_BKP_URL}${
              window.location.pathname
            }?timestamp=${Date.now()}`}
          ></object>

          {/* to run in local host, uncomment and run your react-demo too */}
          {/* <object
            style={{ width: "100%", height: "100%" }}
            data={`${"http://localhost:3000"}${
              window.location.pathname
            }?timestamp=${Date.now()}`}
          ></object> */}
        </div>
      </>
    );
  }, [flag, props]);
  return <>{module}</>;
};

export default DemoPage;
