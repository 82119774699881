import { ReactComponent as Assort_en } from "assets/home/assort.svg";
import { ReactComponent as Ada_en } from "assets/home/ada.svg";
import { ReactComponent as Attribute_en } from "assets/home/attribute.svg";
import { ReactComponent as Price_en } from "assets/home/mark.svg";
import { ReactComponent as Space_en } from "assets/home/space.svg";
import { ReactComponent as Store_en } from "assets/home/store.svg";
import { ReactComponent as Test_en } from "assets/home/test.svg";
import { ReactComponent as Monday_en } from "assets/home/monday.svg";
import { ReactComponent as Plan_en } from "assets/home/plansmart.svg";
import { ReactComponent as Track_en } from "assets/home/Tracksmart.svg";
import { ReactComponent as Inventory_en } from "assets/home/inventory.svg";
import { ReactComponent as User_en } from "assets/home/users.svg";
import { ReactComponent as Notification_en } from "assets/home/notification.svg";
import { ReactComponent as MasterData_en } from "assets/home/masterData.svg";
import { ReactComponent as MasterDataView } from "assets/home/Workflow.svg";
import { ReactComponent as Cluster_en } from "assets/home/clustersmart.svg";
import {
  uamSideBarOptions,
  notificationSideBarOptions,
  moduleConfigurationSideBarOptions,
} from "commonComponents/core-layout";
import { sideBarOptions as assortSideBarOptions } from "modules/react-demo/routes/assort-smart-routes";
import { sideBarOptions as planSideBarOptions } from "modules/plansmart/routes-plansmart";
import { sideBarOptions as trackSideBarOptions } from "modules/tracksmart/routes-tracksmart/routes";
import { sideBarOptions as spaceSideBarOptions } from "modules/spacesmart/routes-spacesmart/routes";
import { sideBarOptions as marksmartSideBarOptions } from "modules/marksmart/routes-marksmart";
import { sideBarOptions as adaSideBarOptions } from "modules/ada/routes-ada/routes";
import { sideBarOptions as inventorysmartSideBarOptions } from "modules/inventorysmart/routes-inventorysmart/routes";
import { sideBarOptions as genericinventorysmartSideBarOptions } from "modules/genericinventorysmart/routes-inventorysmart/routes";
import { sideBarOptions as clusterSmartSideBarOptions } from "modules/clustersmart/routes-clustersmart/routes";
import { sideBarOptions as priceSmartSideBarOptions } from "modules/pricesmart/routes-pricesmart/routes";
import { sideBarOptions as tradeSmartSideBarOptions } from "modules/tradesmart/routes-tradesmart/routes";
import { sideBarOptions as promoSmartSideBarOptions } from "modules/promosmart/routes-promosmart/routes";
import { sideBarOptions as ootbSideBarOptions } from "modules/ootb/routes-ootb/routes";
import { sideBarOptions as adaChatbotSideBarOptions } from "modules/adachatbot/routes-adachatbot/routes";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import React from "react";

export const allPlatformApps = (clientName) => {
  return {
    config: [
      {
        label: "Module Configurator",
        desc: "Configuring product modules",
        url: moduleConfigurationSideBarOptions[0].link,
        logo: <User_en />,
        type: "config",
        title: "module configurator",
        active: false,
      },
      {
        label: "Application Access Management",
        desc: "User Management, Access",
        url: uamSideBarOptions[0].link,
        logo: <User_en />,
        type: "config",
        title: "application access management",
        active: false,
      },
      {
        label: "Notification Center",
        desc: "Alerts, Checklists and Warning configuration",
        url: notificationSideBarOptions[0].link,
        logo: <Notification_en />,
        type: "config",
        title: "notification",
        active: false,
      },
      {
        label: "Workflow Input Center",
        desc: "Store, Product and DC Configurations",
        url: "/product-mapping",
        logo: <MasterData_en />,
        type: "config",
        title: "workflow input center",
        active: false,
      },
      {
        label: "Master Data View",
        desc: "Inventory and product source of truth",
        url: "/product-mapping",
        logo: <MasterDataView />,
        type: "config",
        title: "workflow input center",
        active: false,
      },
    ],
    featuredApp: [
      {
        label: "Plan Smart",
        desc:
          "PlanSmart leverages ADA powered AI/ML based long range forecasts to generate merchandising financial plans at multiple levels of product hierarchy. Also, generates optimized plans based on key constraints and strategic objectives.",
        web:
          "https://www.impactanalytics.co/solutions/merchandise-financial-planning/",
        logo: <Plan_en />,
        type: "platformApps",
        title: "plan-smart",
        category: "core",
      },
      {
        label: "PriceSmart-Initial Pricing",
        desc:
          "Unlock optimal initial pricing with our cutting-edge price optimization tool, maximizing margins and competitiveness for your products.",
        web:
          "https://www.impactanalytics.co/solutions/retail-price-optimization/",
        logo: <Price_en />,
        type: "platformApps",
        title: "pricesmart-initial-pricing",
        category: "core",
      },
      {
        label: "Promo Smart",
        desc: "PromoSmart description.",
        web:
          "https://www.impactanalytics.co/solutions/trade-promotion-management/",
        logo: <Monday_en />,
        type: "platformApps",
        title: "promo-smart",
        category: "core",
      },
      {
        label: "Mark Smart",
        desc:
          "Drive retail markdown optimization to maximize revenue and margin growth. Streamline markdowns to optimize total return.",
        web:
          "https://www.impactanalytics.co/solutions/retail-price-optimization/",
        logo: <Price_en />,
        type: "platformApps",
        title: "mark-smart",
        category: "core",
      },
      {
        label: "Track Smart",
        desc:
          "Tracksmart leverages ADA powered AI/ML based long range forecasts to generate merchandising financial plans at multiple levels of product hierarchy. Also, generates optimized plans based on key constraints and strategic objectives.",
        web:
          "https://www.impactanalytics.co/solutions/merchandise-financial-planning/",
        logo: <Track_en />,
        type: "platformApps",
        title: "track-smart",
        category: "core",
      },
      {
        label: "Space Smart",
        desc:
          "Spacesmart leverages ADA powered AI/ML based long range forecasts to generate merchandising financial plans at multiple levels of product hierarchy. Also, generates optimized plans based on key constraints and strategic objectives.",
        web:
          "https://www.impactanalytics.co/solutions/merchandise-financial-planning/",
        logo: <Space_en />,
        type: "platformApps",
        title: "space-smart",
        category: "core",
      },
      {
        label: "Assort Smart",
        desc:
          "With easy-to-use and intuitive interfaces and tools, you will get more granular planning solutions that incorporate our intelligent clustering solution. And our built-in AI and machine learning allow your team to be more efficient, turning out better assortment plans with fewer invested hours of your team’s labor. Our intelligent retail assortment planning technology lets you analyze your inventory data to gain actionable insights that allow you to improve your inventory management process.",
        web:
          "https://www.impactanalytics.co/solutions/retail-assortment-planning/",
        logo: <Assort_en />,
        type: "platformApps",
        title: "assort-smart",
        category: "core",
      },
      {
        label: "Inventory Smart",
        desc:
          "Optimize inventories through retail allocation that leverages predictive analytics for the greatest accuracy in even the most complex allocation and replenishment businesses. Our solution is highly automatable and accurate using leading edge machine learning models that allow for rapid “what-if” simulations to ensure the business is making the right decisions.",
        web: "https://www.impactanalytics.co/solutions/inventory-allocation/",
        logo: <Inventory_en />,
        type: "platformApps",
        title: "inventorysmartold",
        category: "core",
      },
      {
        label: "Inventory Smart-Generic",
        desc:
          "Optimize inventories through retail allocation that leverages predictive analytics for the greatest accuracy in even the most complex allocation and replenishment businesses. Our solution is highly automatable and accurate using leading edge machine learning models that allow for rapid “what-if” simulations to ensure the business is making the right decisions.",
        web: "https://www.impactanalytics.co/solutions/inventory-allocation/",
        logo: <Inventory_en />,
        type: "platformApps",
        title: "generic-inventory-smart",
        category: "core",
      },
      {
        label: "IA Chatbot",
        desc:
          "Leverage the best-in-class retail and CPG forecasting engine for identifying recent trends, seasonality, and other unique demand drivers, all in one place. Push forecasts into any existing planning and pricing systems. For years, the traditional forecasting algorithms have leaned very heavily on historical data. But with rapid changes in product preferences and consumption patterns, businesses need a more robust framework that includes factors other than just historical data.",
        web: "https://www.impactanalytics.co/",
        logo: <Ada_en />,
        type: "platformApps",
        title: "ada-chatbot",
        category: "core",
      },
      {
        label: "Forecast Smart",
        desc:
          "Leverage the best-in-class retail and CPG forecasting engine for identifying recent trends, seasonality, and other unique demand drivers, all in one place. Push forecasts into any existing planning and pricing systems. For years, the traditional forecasting algorithms have leaned very heavily on historical data. But with rapid changes in product preferences and consumption patterns, businesses need a more robust framework that includes factors other than just historical data.",
        web: "https://www.impactanalytics.co/",
        logo: <Ada_en />,
        type: "platformApps",
        title: "ada",
        category: "core",
      },
      {
        label: "Cluster Smart",
        desc:
          "With easy-to-use and intuitive interfaces and tools, you will get more granular planning solutions that incorporate our intelligent clustering solution. And our built-in AI and machine learning allow your team to be more efficient, turning out better assortment plans with fewer invested hours of your team’s labor. Our intelligent retail assortment planning technology lets you analyze your inventory data to gain actionable insights that allow you to improve your inventory management process.",
        web: "https://www.impactanalytics.co/",
        logo: <Cluster_en />,
        type: "platformApps",
        title: "cluster-smart",
        category: "core",
      },
      {
        label: "Trade Smart",
        desc: "TradeSmart description.",
        web:
          "https://www.impactanalytics.co/solutions/trade-promotion-management/",
        logo: <Monday_en />,
        type: "platformApps",
        title: "trade-smart",
        category: "core",
      },
      {
        label: "Price Smart",
        desc:
          "PriceSmart leverages AI to drive decisions on initial, promotional, and clearance pricing to achieve your business objectives. A single application to ensure lifecycle pricing, promotions, pre-season pricing, and end-of-life markdown optimization.",
        web:
          "https://www.impactanalytics.co/solutions/retail-price-optimization/",
        logo: <Price_en />,
        type: "platformApps",
        title: "price-smart",
        category: "core",
      },
      {
        label: "Item Smart",
        desc:
          "ItemSmart is an Item level sales planning tool that supports week level edits for their KPIs.",
        web:
          "https://www.impactanalytics.co/solutions/retail-price-optimization/",
        logo: <Plan_en />,
        type: "platformApps",
        title: "item-smart",
        category: "core",
      },
      {
        label: "Market Smart",
        desc: "MarketSmart Description",
        web:
          "https://www.impactanalytics.co/solutions/retail-price-optimization/",
        logo: <Assort_en />,
        type: "platformApps",
        title: "market-smart",
        category: "core",
      },
      {
        label: "OOTB-Inventory Smart",
        desc:
          "OOTB Inventory Smart optimizes in-store execution using AI powered recommendations to identify issues affecting local product sales. It assists store managers to identify and resolve gaps in store operations at a product level, improving store performance. Available on mobile and desktop, the application is easy to use and can be quickly implemented.",
        web: "https://www.impactanalytics.co/",
        logo: <Inventory_en />,
        type: "platformApps",
        title: "ootb-inventory-smart",
        category: "core",
      },
      {
        label: "OOTB Plan Smart",
        desc:
          "PlanSmart leverages ADA powered AI/ML based long range forecasts to generate merchandising financial plans at multiple levels of product hierarchy. Also, generates optimized plans based on key constraints and strategic objectives.",
        web:
          "https://www.impactanalytics.co/solutions/merchandise-financial-planning/",
        logo: <Plan_en />,
        type: "platformApps",
        title: "ootb-plan-smart",
        category: "core",
      },
      {
        label: "OOTB Item Smart",
        desc:
          "ItemSmart is an Item level sales planning tool that supports week level edits for their KPIs.",
        web:
          "https://www.impactanalytics.co/solutions/merchandise-financial-planning/",
        logo: <Plan_en />,
        type: "platformApps",
        title: "ootb-item-smart",
        category: "core",
      },
      {
        label: "Test Smart",
        desc:
          "TestSmart uses artificial intelligence and machine learning to run multiple simultaneous hypothesis-driven tests across stores, merchandising, marketing, promotions, and more. Take the guesswork out of managing your business, and make statistically informed decisions to drive growth in your business. Our test and learn methodology allows you to rapidly create tests using our guided workflows, and rapidly analyze results to drive immediate margin gains and higher conversion rates. Create powerful hypothesis testing campaigns while keeping your costs low with our next-gen patent-pending test and learn platform.",
        url: "/test-smart/dashboard",
        web:
          "https://www.impactanalytics.co/solutions/test-and-learn-solution/",
        logo: <Test_en />,
        type: "platformApps",
        title: "test-smart",
        category: "smartBI",
      },
      {
        label: "Monday Smart",
        desc:
          "MondaySmart makes Business Intelligence for retail easy through automated insights based on your data sources so you can get instant insight into critical drivers affecting your business.",
        web:
          "https://www.impactanalytics.co/solutions/data-driven-decision-making-reporting/",
        logo: <Monday_en />,
        type: "platformApps",
        title: "monday-smart",
        category: "smartBI",
      },
      {
        label: "Attribute Smart",
        desc:
          "Minimize costly manual efforts with AttributeSmart and ensure 95%+ accuracy in automated product tagging workflow for higher EBITDA and CSAT scores. Retail winners invariably have the best product attribution - knowing more about each product they sell and who it will sell to. Increasingly, they are automating this process to improve accuracy and team productivity.",
        web:
          "https://www.impactanalytics.co/solutions/automated-product-tagging/",
        logo: <Attribute_en />,
        type: "platformApps",
        title: "attribute",
        category: "smartBI",
      },
      {
        label: "Store Smart",
        desc:
          "StoreSmart optimizes in-store execution using AI powered recommendations to identify issues affecting local product sales. It assists store managers to identify and resolve gaps in store operations at a product level, improving store performance. Available on mobile and desktop, the application is easy to use and can be quickly implemented.",
        web: "https://www.impactanalytics.co/",
        logo: <Store_en />,
        type: "platformApps",
        title: "store-smart",
        category: "smartBI",
      },
    ],
  };
};

// Temporary data till we integrate Client Information using API's
export const clientInfo = {
  assortsmart: {
    clients: [
      {
        clientName: "Vera Bradley",
        start_year: "2021",
        end_year: "-",
      },
      {
        clientName: "Calvin Klien",
        start_year: "2021",
        end_year: "-",
      },
      {
        clientName: "Puma",
        start_year: "2020",
        end_year: "-",
      },
    ],
  },
  marksmart: {
    clients: [
      {
        clientName: "Vera Bradley",
        start_year: "2021",
        end_year: "-",
      },
    ],
  },
  ada: {
    clients: [
      {
        clientName: "Vera Bradley",
        start_year: "2021",
        end_year: "-",
      },
    ],
  },
  plansmart: {
    clients: [
      {
        clientName: "Vera Bradley",
        start_year: "2021",
        end_year: "-",
      },
    ],
  },
  inventorysmart: {
    clients: [
      {
        clientName: "Vera Bradley",
        start_year: "2021",
        end_year: "-",
      },
      {
        clientName: "Signet",
        start_year: "2021",
        end_year: "-",
      },
      {
        clientName: "Puma",
        start_year: "2020",
        end_year: "-",
      },
      {
        clientName: "Ashley Stewart",
        start_year: "2020",
        end_year: "-",
      },
      {
        clientName: "Tapestry",
        start_year: "2020",
        end_year: "-",
      },
    ],
  },
  pricesmart: {
    clients: [
      {
        clientName: "Vera Bradley",
        start_year: "2019",
        end_year: "-",
      },
      {
        clientName: "BJ's Wholesale Club",
        start_year: "2019",
        end_year: "-",
      },
      {
        clientName: "PSP",
        start_year: "2017",
        end_year: "-",
      },
    ],
  },
  testsmart: {
    clients: [
      {
        clientName: "Vera Bradley",
        start_year: "2021",
        end_year: "-",
      },
    ],
  },
};
