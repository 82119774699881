import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { NavLink, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { logoutUser } from "../../actions/authActions";
import { connect, useDispatch } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import Drawer from "@mui/material/Drawer";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import logo from "../../assets/logo.png";
import logoNoText from "../../assets/logo_no_text.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import globalStyles from "Styles/globalStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";

const sidebarIcon = (itemIcon, itemTitle) => {
  if (itemIcon instanceof Object) {
    return itemIcon;
  } else {
    return <img className="icon" src={itemIcon} alt={itemTitle} />;
  }
};

const StyledListItem = withStyles((theme) => ({
  root: {
    "&.Mui-selected": {
      backgroundColor: "#000000", // theme.palette.primary.main
      // borderLeft: "4px solid #93BDE9",
      marginRight: "-4px",
      position: "relative",
    },
  },
}))(ListItem);

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.customVariables.navWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  "&a": {
    textDecoration: "none",
  },
  drawerOpen: {
    width: theme.customVariables.navWidth,
    overflowX: "hidden",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.customVariables.closedNavWidth,
    [theme.breakpoints.up("sm")]: {
      width: theme.customVariables.closedNavWidth,
    },
  },
  listPadding: {
    padding: "1rem 1.5rem",
  },
  list: {
    flex: 1,
  },
  listItemIcon: {
    color: "unset",
    minWidth: "unset",
    width: "1.435rem",

    "& .MuiSvgIcon-root": {
      fontSize: "1.375rem",
    },
  },
  listItem: {
    color: theme.palette.common.white,
    padding: 0,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  listItemText: {
    margin: "0 0 0 2rem",

    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      ...theme.typography.h5,
    },
  },
  listitemSidebar: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.white,
    cursor: "pointer",
  },
  sidebarMenu: {
    width: "100%",
    textDecoration: "none",
  },
  sidebarMiddle: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    height: theme.customVariables.closedNavWidth,
    padding: theme.spacing(2),
  },
  logoImage: {
    height: "100%",
  },
  menuIcon: {
    color: theme.palette.common.white,
    width: "1.125rem",
    height: "1.125rem",
  },
  drawerToggler: {
    display: "flex",
    // display: "none",
    background: "#1A2233",
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: "50%",
    bottom: "15%",
    cursor: "pointer",
    left: (props) => (props.isDrawerOpen ? "22.5rem" : "4rem"), // theme.customVariables.closedNavWidth - 0.5; //theme.customVariables.navWidth
    position: "fixed",
    transform: "translateX(-50%)",
    transition: (props) =>
      props.isDrawerOpen ? "left 225ms ease" : "left 195ms ease",
    zIndex: 999,
  },
  logout: {
    cursor: "pointer",
  },
  drawerAccordion: {
    cursor: "pointer",
    // backgroundColor: `${theme.palette.primary.dark}`,
    "box-shadow": "none",
    display: "grid",
    // padding: "1rem 0",
    "&.Mui-expanded": {
      margin: 0,
      padding: 0,
    },

    "&::before": {
      backgroundColor: "transparent",
      height: "0",
    },

    "& .MuiAccordionSummary-root": {
      color: `${theme.palette.common.white}`,
      backgroundColor: "#1a2233", //`${theme.palette.primary.dark}`,
      border: "0",
      "padding-left": `${theme.typography.pxToRem(25)}`,

      "&.Mui-expanded .MuiAccordionSummary-content": {
        marginBlock: 0,
      },

      "&::before": {
        backgroundColor: "transparent",
      },
    },

    "& .MuiAccordionSummary-expandIconWrapper": {
      color: `${theme.palette.common.white}`,
      padding: "0",
      marginRight: "0rem",
      width: "1.435rem",

      "& .MuiSvgIcon-root": {
        fontSize: "1.375rem",
      },
    },

    "& .MuiAccordionDetails-root": {
      padding: "0",
      paddingLeft: (props) => (props.isDrawerOpen ? "16px" : "0px"),
      color: `${theme.palette.common.white}`,
      backgroundColor: "#1a2233", //`${theme.palette.primary.dark}`,
      border: "0",
      "& a": {
        // "padding-left": "2rem",
      },
      "&::before": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const SideBar = ({ options, location, pathPrefix, logoutUser, props }) => {
  const wrapperRef = useRef();
  const [isActive, setisActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const classes = useStyles({ isDrawerOpen: isActive });
  const globalClasses = globalStyles();
  const dispatch = useDispatch();
  const toggleSideBarExpansionHandler = () => {
    setisActive(!isActive);
  };

  /**
   * @func
   * @desc Find the order for current location in the provided route Options
   * @param {Object} optionList
   */
  const findCurrentOrder = (optionList) => {
    let order;
    optionList.some((option) => {
      if (option.isParent) {
        const childOrder = findCurrentOrder(option.childList);
        if (childOrder) {
          order = childOrder;
          return true;
        }
      } else if (option.link === location.pathname) {
        order = option.order;
        return true;
      }
    });
    return order;
  };

  useEffect(() => {
    setSelectedIndex(findCurrentOrder(options));
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, [options]);

  useEffect(() => {
    setSelectedIndex(findCurrentOrder(options));
  }, [options]);

  useEffect(() => {
    props?.setUserPlatformScreen(`${location.pathname.split("/")[2]}`);
  }, [location, selectedIndex]);

  const handleClick = (e) => {
    if (wrapperRef.current.contains(e.target)) {
      return;
    }
    setisActive(false);
  };
  const handleListItemClick = async (_event, order, path) => {
    props.setActiveScreenName(null);
    setSelectedIndex(order);
    dispatch({
      type: "REFRESH_ROUTE",
      payload: path,
    });
  };

  const logoutCurrentUser = () => {
    Cookies.remove("authToken", {
      domain:
        window.location.hostname === "localhost"
          ? "localhost"
          : "impactsmartsuite.com",
    });

    props.history.push("/login", {
      state: { client: window?.location?.pathname },
    });
  };

  return (
    <div
      className={`impact-platform-sidebar ${isActive ? "open-sidebar" : ""}`}
      ref={wrapperRef}
    >
      <div
        className="menu-hamburger"
        onClick={() => toggleSideBarExpansionHandler()}
      >
        {isActive ? (
          <NavLink
            to={
              window.location.pathname.includes("configurator")
                ? "/" +
                  window.location.pathname?.split("/")[1] +
                  "/configurator/home"
                : "/" + window.location.pathname?.split("/")[1] + "/home"
            }
          >
            <img src={logo} alt="Impact analytics" />
          </NavLink>
        ) : (
          <MenuIcon />
        )}
      </div>
      <div
        className={classes.drawerToggler}
        onClick={() => toggleSideBarExpansionHandler()}
      >
        {!isActive && <ArrowForwardIcon className={classes.menuIcon} />}
        {isActive && <ArrowBackIcon className={classes.menuIcon} />}
      </div>
      <div className={classes.sidebarMiddle}>
        <List className={classes.list}>
          {options.map((item, i) => {
            const {
              isParent,
              title,
              icon,
              childList,
              order,
              disabled,
              link,
            } = item;
            return isParent ? (
              <Accordion className={classes.drawerAccordion} key={title}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.listPadding}
                >
                  <ListItemIcon
                    className={`${classes.listItemIcon} ${globalClasses.layoutAlignCenter}`}
                  >
                    {sidebarIcon(icon, title)}
                  </ListItemIcon>
                  <Tooltip title={title}>
                    <ListItemText
                      className={classes.listItemText}
                      primary={item.title}
                    />
                  </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                  {childList.map((childItem, i) => {
                    const {
                      title: childTitle,
                      order: childOrder,
                      disabled: isDisabled,
                      icon: childIcon,
                      link,
                    } = childItem;
                    return (
                      <Tooltip title={childTitle} key={childTitle}>
                        <StyledListItem
                          id={`sidebar-item-${i}`}
                          className={classes.listItem}
                          classes={{ selected: classes.listItemSelected }}
                          button
                          key={childTitle}
                          selected={selectedIndex === childOrder}
                          data-screen={childTitle}
                          onClick={(event) =>
                            handleListItemClick(event, childOrder)
                          }
                          disabled={isDisabled}
                        >
                          <NavLink
                            to={pathPrefix + link}
                            className={`${classes.sidebarMenu} ${classes.listPadding}`}
                            id={childTitle}
                            disabled={disabled}
                          >
                            <div className={classes.listitemSidebar}>
                              <ListItemIcon
                                className={`${classes.listItemIcon} ${globalClasses.layoutAlignCenter}`}
                              >
                                {sidebarIcon(childIcon, childTitle)}
                              </ListItemIcon>
                              <ListItemText
                                className={classes.listItemText}
                                primary={childTitle}
                              />
                            </div>
                          </NavLink>
                        </StyledListItem>
                      </Tooltip>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ) : (
              <Tooltip title={title} key={title}>
                <StyledListItem
                  id={`sidebar-item-${i}`}
                  className={classes.listItem}
                  classes={{ selected: classes.listItemSelected }}
                  button
                  key={title}
                  selected={selectedIndex === order}
                  data-screen={title}
                  onClick={(event) => handleListItemClick(event, order,link)}
                  disabled={disabled}
                >
                  <NavLink
                    to={pathPrefix + link}
                    className={`${classes.sidebarMenu} ${classes.listPadding}`}
                    id={title}
                    disabled={disabled}
                  >
                    <div className={classes.listitemSidebar}>
                      <ListItemIcon
                        className={`${classes.listItemIcon} ${globalClasses.layoutAlignCenter}`}
                      >
                        {sidebarIcon(icon, title)}
                      </ListItemIcon>
                      <ListItemText
                        className={classes.listItemText}
                        primary={title}
                      />
                    </div>
                  </NavLink>
                </StyledListItem>
              </Tooltip>
            );
          })}
        </List>
      </div>
      <div className={classes.listPadding}>
        <div
          className={classes.listitemSidebar}
          onClick={() => logoutCurrentUser()}
        >
          <LogoutIcon
            className={`${classes.listItemIcon} ${globalClasses.layoutAlignCenter} ${classes.logout}`}
          />
          <Typography className={classes.listItemText}>Logout</Typography>
        </div>
      </div>
    </div>
  );
};

SideBar.defaultProps = {
  options: [],
  pathPrefix: "",
};

SideBar.propTypes = {
  options: PropTypes.array,
  pathPrefix: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
};

export default connect(null, { logoutUser })(withRouter(SideBar));
