import {
  SET_USER_PLATFORM_SCREENS,
  SET_USER_SCREENS,
  SET_ACTIVE_APP_NAME,
} from "../actions/types";

export const initialState = {
  userScreenData: {},
  userPlatformScreenName: {},
  activeAppName: "",
  routeRefreshFlags: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_SCREENS:
      return {
        ...state,
        userScreenData: {
          // ...action.payload,
          ...state.userScreenData,
          inventorysmart: { screens: ["All"], appName: "inventorysmart" },
          tracksmart: { screens: ["All"], appName: "tracksmart" },
          plansmart: { screens: ["All"], appName: "plansmart" },
          assortsmart: { screens: ["All"], appName: "assortsmart" },
          tradesmart: { screens: ["All"], appName: "tradesmart" },
        },
      };
    case SET_USER_PLATFORM_SCREENS:
      return {
        ...state,
        userPlatformScreenName: action.payload,
      };
    case SET_ACTIVE_APP_NAME:
      return {
        ...state,
        activeAppName: action.payload,
      };
    case "REFRESH_ROUTE":
      let v = state.routeRefreshFlags[action.payload];
      if (v === undefined) {
        v = true;
      } else {
        v = !v;
      }
      return {
        ...state,
        routeRefreshFlags: { ...state.routeRefreshFlags, [action.payload]: v },
      };
    default:
      return state;
  }
};
